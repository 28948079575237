<template>
  <section>
    <list
      :filter-table-key="'renewPending'"
      :type="3"></list>
  </section>
</template>

<script>
import list from '../policy/list'
export default {
  name: 'pending',
  components:{
    list
  },
}
</script>

<style scoped>

</style>
